<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>{{ editing ? '編輯管理員' : '添加管理員' }}</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-8 col-sm-10">
            <el-form ref="form" :model="form" :rules="rules" label-width="auto">
              <el-form-item label="賬號" prop="account">
                <el-input v-model="form.account" :disabled="editing" placeholder="請輸入賬號" />
              </el-form-item>
              <el-form-item label="密碼" prop="password">
                <el-input
                  v-model="form.password"
                  autocomplete="off"
                  :placeholder="editing ? '如果不需要修改密碼，請留空' : '請輸入密碼'"
                  show-password />
              </el-form-item>
              <el-form-item label="確認密碼" prop="password_confirmation">
                <el-input
                  v-model="form.password_confirmation"
                  autocomplete="off"
                  :placeholder="editing ? '如果不需要修改密碼，請留空' : '請確認密碼'"
                  show-password />
              </el-form-item>
              <el-form-item label="管理門店" prop="post_offices" v-if="!form.all_privileges">
                <el-checkbox-group v-model="form.post_offices">
                  <el-checkbox v-for="item in postOfficeList" :key="item.id" :label="item.id">
                    {{ item.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit()">{{ editing ? '修改' : '添加' }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '../../helpers';

export default {
  name: 'AdminSave',
  mounted() {
    if (!this.postOfficeList.length) {
      this.$store.dispatch('fetchOffice');
    }
  },
  data() {
    const setPass = (rule, value, callback) => {
      if (value === '') {
        if (this.editing) {
          callback();
        } else {
          callback(new Error('请输入密码'));
        }
      } else {
        if (this.form.password_confirmation !== '') {
          this.$refs.form.validateField('password_confirmation');
        }
        callback();
      }
    };
    const setCheckPass = (rule, value, callback) => {
      if (value === '') {
        if (this.editing) {
          callback();
        } else {
          callback(new Error('请再次输入密码'));
        }
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        account: '',
        password: '',
        password_confirmation: '',
        all_privileges: false,
        post_offices: [],
      },
      rules: {
        account: { required: true, message: '請輸入賬號', trigger: 'blur' },
        password: [
          { required: true, validator: setPass, trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '密碼長度在6到20个字符',
            trigger: 'blur',
          },
        ],
        password_confirmation: { required: true, validator: setCheckPass, trigger: 'blur' },
        post_offices: { required: true, message: '請選擇管理門店', trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapState({
      postOfficeList: state => state.postOffice.postOfficeList,
    }),
    editing() {
      return !!this.$route.params.id;
    },
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id) {
          this.rules.password[0].required = false;
          this.rules.password_confirmation.required = false;
          const loading = getLoadingInstance();
          this.$api.admin.get(id).then(({ data: response }) => {
            this.rules.post_offices.required = !response.data.all_privileges;
            this.form.account = response.data.account;
            this.form.id = response.data.id;
            this.form.all_privileges = response.data.all_privileges;
            this.form.password = '';
            this.form.password_confirmation = '';
            response.data.post_offices.forEach((item) => {
              this.form.post_offices.push(item.id);
            });
          }).catch((error) => {
            showErrorMessage(error);
            this.$router.push('/');
          }).finally(() => {
            loading.close();
          });
        } else {
          this.rules.password[0].required = true;
          this.rules.password_confirmation.required = true;
          this.form = {
            account: '',
            password: '',
            password_confirmation: '',
            all_privileges: false,
            post_offices: [],
          };
        }
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.admin.save(this.form).then(() => {
            this.$notify({
              title: '成功',
              message: this.editing ? '修改成功' : '管理员添加成功',
              type: 'success',
            });
            this.$router.push({ name: 'admin-list' });
          }).catch((error) => {
            showErrorMessage(error);
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
  },
};
</script>

<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>管理员列表</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <v-client-table :data="adminList" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template slot="all_privileges" slot-scope="props">
            {{ props.row.all_privileges ? '是' : '否' }}
          </template>
          <template slot="post_offices" slot-scope="props">
            <router-link
              :to="{ name: 'post-office', params: { id: postOffice.id } }"
              v-for="postOffice in props.row.post_offices"
              :key="postOffice.id"
              class="mr-2"
            >
              <el-tag>{{ postOffice.name }}</el-tag>
            </router-link>
          </template>
          <template slot="operation" slot-scope="props">
            <router-link class="btn btn-primary btn-sm" :to="{ name: 'admin', params: { id: props.row.id } }">
              編輯
            </router-link>
            <el-popconfirm title="確定要刪除這個管理員嗎？" @confirm="deleteAdmin(props.row.id)">
              <button
                slot="reference"
                class="btn btn-danger btn-sm ml-2"
                :disabled="currentUser.id === props.row.id"
              >
                刪除
              </button>
            </el-popconfirm>
          </template>
        </v-client-table>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'AdminList',
  data() {
    return {
      tableConfigs: {
        columns: ['id', 'account', 'post_offices', 'all_privileges', 'created_at', 'operation'],
        options: {
          headings: {
            id: 'ID',
            account: '賬號',
            created_at: '註冊時間',
            all_privileges: '超級管理員',
            post_offices: '管理門店',
            operation: '操作',
          },
          filterable: false,
          sortable: [],
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchAdmin');
  },
  computed: {
    ...mapState({
      adminList: state => state.admin.adminList,
    }),
    ...mapGetters(['currentUser']),
  },
  methods: {
    deleteAdmin(id) {
      this.$store.dispatch('deleteAdmin', id).then(() => {
        this.$notify.success({
          title: '已刪除該管理員',
        });
      });
    },
  },
};
</script>
